import React from "react";

import { Row, Col, Typography, Button } from "antd";
import classes from "./StakingCard.module.css";

import * as Vesting from "../../blockchain/contracts/vesting";

const stakeFields = [
  "TOTAL CLAIMED FTML",
  "AVAILABLE FTML TO CLAIM",
  "TOTAL TOKENS TO BE RECEIVED",
];

function InfoTable({ fields }) {
  const [data, setData] = React.useState([0, 0, 0]);

  React.useEffect(() => {
    window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
      if (!accounts.length) return;
      const account = accounts[0];
      Promise.all([
        Vesting.getFTMLTotalToReceive(account),
        Vesting.getFTMLTotalToBeVested(account),
        Vesting.claimableFTMLCurrentCycle(account),
      ]).then(([TTR, TTV, CCC]) => {
        setData([
          Number(TTR - TTV).toFixed(2),
          Number(CCC).toFixed(2),
          Number(TTR).toFixed(2),
        ]);
      });
    });
  }, []);

  return (
    <div>
      <div className={classes.table}>
        <div>
          {stakeFields.map((item, index) => {
            return (
              <Row
                key={index}
                justify="space-between"
                align="middle"
                className={classes.dataRow}
              >
                <Col>
                  <Typography variant="body1" className={classes.key}>
                    {item}
                  </Typography>
                </Col>
                <Col>
                  <Typography
                    variant="body1"
                    align="right"
                    className={classes.value}
                  >
                    {data[index]}
                  </Typography>
                </Col>
              </Row>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default function StakingComponent(props) {
  const [claimable, setClaimable] = React.useState();

  React.useEffect(() => {
    window.ethereum.request({ method: "eth_accounts" }).then((accounts) => {
      if (!accounts.length) return;
      const account = accounts[0];
      Promise.all([Vesting.claimableFTMLCurrentCycle(account)]).then(
        ([CCC]) => {
          setClaimable(CCC);
        }
      );
    });
  }, []);

  const claim = () => {
    window.ethereum
      .request({ method: "eth_accounts" })
      .then(async (accounts) => {
        await Vesting.claim(accounts[0], claimable);
      })
      .catch((error) => {
        console.error(`Failed with Error: ${error.message}`);
      });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Vesting
      </Typography>
      <InfoTable fields={props.fields} />
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={claim}
        // eslint-disable-next-line
        disabled={Number(claimable).toFixed(3) == 0}
        fullWidth
      >
        Claim
      </Button>
    </div>
    // {/* <div style={{ paddingTop: "60px" }}></div>
    // <ProgressBar progress={props.sold} /> */}
  );
}
